import React, { Component } from "react";

import styled from 'styled-components';
import { media } from 'components/Theme/Styles';
import { Container, Card  } from 'components/Theme/Grid';

import RecipeCard  from 'components/Page/Recipe/RecipeCard';


const RecipesContainer = styled(Container)`
  ${media.lessThan("tablet")`
    flex-direction:row;
  `};
`;

class Recipes extends Component {

    render() {
        const recipes = this.props.data;
        const extraCards = (Math.ceil(recipes.length/3)*3) - recipes.length

        return ( 
          <RecipesContainer>
            {recipes.map((recipe, i) => {
              return <RecipeCard data={ recipe.node }
                                 key={`recipe_${i}`}
                                 size={3}
                                 sm={1}
                                 neumorphic
                                 hasButton />
            })}

            {Array.from(Array(extraCards), (e, i) => (
              <Card size={3} sm={1}></Card>
            ))}
          </RecipesContainer>
        )
    }
}

export default Recipes