import React, { Component } from "react";
import { graphql } from 'gatsby'
import Layout from "components/layout";
import SEO from "components/seo";
import _ from 'lodash';
import Hero from 'components/Page/Recipes/Hero';
import Recipes from 'components/Page/Recipes/Recipes';

class RecipesList extends Component {
    render() {
        const listPage = this.props.data.contentfulRecipesList
        const recipes = this.props.data.allContentfulRecipe.edges
        const heroData = _.pickBy(listPage, (v, k) => { return /^hero/.test(k) })
        return ( 
          <Layout >
            <SEO title = 'Recipes'/>
            <Hero data = { heroData }/>
            <Recipes data = { recipes }/>
          </Layout >
        )
    }
}

export default RecipesList

// language=GraphQL
export const pageQuery = graphql`
  query RecipesListQuery {
    contentfulRecipesList {
      heroAsset {
        gatsbyImageData
      }
      heroHeadline
      heroSubheadline
      recipesPerRow
    }
    allContentfulRecipe(sort: {fields: createdAt, order: DESC}) {
      edges {
        node {
          tags
          handle
          heroTitle
          diet
          directionsTotalTime
          heroDescription
          heroAsset {
            gatsbyImageData(layout: FULL_WIDTH)
          },
          author
          authorLink
          authorColor
          authorAvatar {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`;