import React, { Component } from "react";
import styled from 'styled-components';
import { media } from 'components/Theme/Styles';
//import { GatsbyImage } from "gatsby-plugin-image"
import { h1 } from 'components/Theme/Copy';
import { Container, Card  } from 'components/Theme/Grid';


const HeroContainer = styled(Container)`
  
`;

const HeroCard = styled(Card)`
  position:relative;
  align-items:center;
  justify-content: center;
  padding:4rem 0;
`;

const HeroCopy = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  flex-direction:column;
  height:100%;
  padding:2rem;
`;

const HeroHeadline = styled(h1)`

`;

class Hero extends Component {

    render() {
        const { heroAsset, heroHeadline, heroSubheadline } = this.props.data;
        return ( 
          <HeroContainer>
            <HeroCard>
              <HeroCopy>
                <HeroHeadline>{ heroHeadline }</HeroHeadline>
              </HeroCopy>
            </HeroCard>
          </HeroContainer>
        )
    }
}



export default Hero